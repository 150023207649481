const CONFIG = {
    'ga': 'G-6C4Z9NHW8J',
    'whiteboard_id': 'd4cc3728297609add1a00aab108e90c4e57a1c378cfc2307c251745bf7d2a884',
    'title': '数字花园🌱', // 站点标题
    'pages': {
        'Articles': '9f31ea21-90b9-4523-b8d5-cb33b7a01bda',
        'Projects': '3dd9a603-a7f3-44e9-a6d7-cd2ebda08952',
        // 'Activity':'activity', // 花园活跃状态页面
        'About': '3a433c0b-e2e1-4722-8a88-a17e9aa2b927'
    },
    'server': '1214087861653995540', // Discord 服务器 ID，非必填，填写后将在网站中显示聊天入口
    'channel': '1214087861653995545' // Discord 频道 ID，非必填，填写后将在网站中显示聊天入口
}

export default CONFIG

